// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
import { APIResource } from "../../resource.mjs";
export class Speech extends APIResource {
  /**
   * Generates audio from the input text.
   */
  create(body, options) {
    return this._client.post('/audio/speech', {
      body,
      ...options,
      __binaryResponse: true
    });
  }
}
