// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
import { APIResource } from "../../../resource.mjs";
import { isRequestOptions } from "../../../core.mjs";
import { AssistantStream } from "../../../lib/AssistantStream.mjs";
import * as MessagesAPI from "./messages.mjs";
import { Messages, MessagesPage } from "./messages.mjs";
import * as RunsAPI from "./runs/runs.mjs";
import { Runs, RunsPage } from "./runs/runs.mjs";
export class Threads extends APIResource {
  constructor() {
    super(...arguments);
    this.runs = new RunsAPI.Runs(this._client);
    this.messages = new MessagesAPI.Messages(this._client);
  }
  create(body = {}, options) {
    if (isRequestOptions(body)) {
      return this.create({}, body);
    }
    return this._client.post('/threads', {
      body,
      ...options,
      headers: {
        'OpenAI-Beta': 'assistants=v2',
        ...options?.headers
      }
    });
  }
  /**
   * Retrieves a thread.
   */
  retrieve(threadId, options) {
    return this._client.get(`/threads/${threadId}`, {
      ...options,
      headers: {
        'OpenAI-Beta': 'assistants=v2',
        ...options?.headers
      }
    });
  }
  /**
   * Modifies a thread.
   */
  update(threadId, body, options) {
    return this._client.post(`/threads/${threadId}`, {
      body,
      ...options,
      headers: {
        'OpenAI-Beta': 'assistants=v2',
        ...options?.headers
      }
    });
  }
  /**
   * Delete a thread.
   */
  del(threadId, options) {
    return this._client.delete(`/threads/${threadId}`, {
      ...options,
      headers: {
        'OpenAI-Beta': 'assistants=v2',
        ...options?.headers
      }
    });
  }
  createAndRun(body, options) {
    return this._client.post('/threads/runs', {
      body,
      ...options,
      headers: {
        'OpenAI-Beta': 'assistants=v2',
        ...options?.headers
      },
      stream: body.stream ?? false
    });
  }
  /**
   * A helper to create a thread, start a run and then poll for a terminal state.
   * More information on Run lifecycles can be found here:
   * https://platform.openai.com/docs/assistants/how-it-works/runs-and-run-steps
   */
  async createAndRunPoll(body, options) {
    const run = await this.createAndRun(body, options);
    return await this.runs.poll(run.thread_id, run.id, options);
  }
  /**
   * Create a thread and stream the run back
   */
  createAndRunStream(body, options) {
    return AssistantStream.createThreadAssistantStream(body, this._client.beta.threads, options);
  }
}
Threads.Runs = Runs;
Threads.RunsPage = RunsPage;
Threads.Messages = Messages;
Threads.MessagesPage = MessagesPage;
