import {inject, Injectable, LOCALE_ID} from "@angular/core";
import {OrderDetails} from "@ai/external/api/adapter/types";
import {SelectSnapshot} from "@ngxs-labs/select-snapshot";
import {ClientState} from "@client/state/client/client.state";
import {IClient} from "@client/domain";
import {SpecialistState} from "@specialist/state/member/specialist.state";
import * as Specialist from "@specialist/domain";
import {ServiceState} from "@service/state/service/service.state";
import {IService} from "@module/service";

import {IAddress} from "@client/domain/interface/i.address";
import {Store} from "@ngxs/store";
import {CURRENT_LANGUAGE_CODE} from "@src/token/language.token";
import {Reactive} from "@utility/cdk/reactive";

export interface IAiRepository {
    getWorkingHours(): string;

    getBarbers(): string;

    getBusySchedules(): string;

    getServices(): string;

    getBusinessInfo(): string;

    getContactInfo(): string;

    createOrder({orderDetails}: { orderDetails: OrderDetails }): string;
}

@Injectable({
    providedIn: 'root'
})
export class AiRepository extends Reactive implements IAiRepository {

    @SelectSnapshot(ClientState.item)
    public clientItem!: IClient;

    @SelectSnapshot(ServiceState.items)
    public services!: IService[];

    private readonly store = inject(Store);
    private readonly currentLanguageCode = inject(CURRENT_LANGUAGE_CODE);

    public specialists: Specialist.ISpecialist[] = [];

    public async init() {
        this.store.select(SpecialistState.items).pipe(
            this.takeUntil(),
        ).subscribe((specialists) => {
            if (!specialists) {
                console.error('Specialists not found');
            } else {
                this.specialists = specialists;
            }
        });
    }

    public getPropertyToReplace() {
        return {
            businessName: this.clientItem.name,
        }
    }

    public getAddressString(address: IAddress): string {
        if (!address) return '';
        const country = this.getLocalizedCountryName(address.country);
        const city = address?.city ? `${address.city}` : '';
        const streetAddressLineOne = address?.streetAddressLineOne ? `${address.streetAddressLineOne}` : '';
        const streetAddressLineTwo = address?.streetAddressLineTwo ? `${address.streetAddressLineTwo}` : '';
        const zipCode = address?.zipCode ? `${address.zipCode}` : '';

        const addressList = [streetAddressLineOne, streetAddressLineTwo, city, country, zipCode];

        return addressList.filter(Boolean).join(', ');
    }

    private getLocalizedCountryName(countryCode: string): string {
        switch (countryCode) {
            case 'UA':
                return $localize`:country_ukraine:Ukraine`;
            case 'DK':
                return $localize`:country_denmark:Denmark`;
            case 'PL':
                return $localize`:country_poland:Poland`;
            default:
                return countryCode;
        }
    }

    public get availableFunctions() {
        return {
            getWorkingHours: this.getWorkingHours.bind(this),
            getBarbers: this.getBarbers.bind(this),
            getBusySchedules: this.getBusySchedules.bind(this),
            getServices: this.getServices.bind(this),
            getBusinessInfo: this.getBusinessInfo.bind(this),
            getContactInfo: this.getContactInfo.bind(this),
            createOrder: this.createOrder.bind(this),
        };
    }

    public getWorkingHours(): string {
        return JSON.stringify(this.clientItem.schedules);
    }

    public getBarbers(): string {
        return JSON.stringify(this.specialists.map(({firstName}) => firstName));
    }

    public getBusySchedules(): string {
        return JSON.stringify({});
    }

    public getServices(): string {
        const services = this.services.reduce((acc, service) => {
            const {durationVersions, languageVersions} = service;
            const foundLanguage = languageVersions.find(({language}) => language === this.currentLanguageCode);
            if (!foundLanguage) {
                return acc;
            }
            acc.push({
                name: foundLanguage.title ?? '',
                price: durationVersions[0].prices[0].price,
                currency: durationVersions[0].prices[0].currency,
                durationInSeconds: durationVersions[0].durationInSeconds
            });
            return acc;
        }, [] as {
            name: string;
            currency: string;
            price: number;
            durationInSeconds: number;
        }[]);
        return JSON.stringify(services);
    }

    public getBusinessInfo(): string {
        return JSON.stringify({
            address: this.getAddressString(this.clientItem.addresses[0]),
            name: this.clientItem.name
        });
    }

    public getContactInfo(): string {
        return JSON.stringify(this.clientItem.contacts);
    }

    public createOrder({orderDetails}: { orderDetails: OrderDetails }): string {
        return 'Order not created successfully'
    }

}
